body {
  margin: 0;
}
html {
  scroll-behavior: smooth !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background: rgb(150, 150, 150);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: gray;
}

.title {
  position: absolute;
  bottom: 100px;
  text-align: center;
  width: 100%;
  font-weight: 100;
}
.card-container {
  width: 100%;
  height: 300px;
  border-radius: 15px;
  perspective: 600px;
}
.card-container1 {
  width: 100%;
  height: 500px;
  border-radius: 15px;
  perspective: 600px;
}
.card {
  position: relative;
  width: 100%;
  height: 100%;
  transition: 1s ease-in-out;
  transform-style: preserve-3d;
  border-radius: 5px;
  box-shadow: 0px 0px 50px #000;
}
.card-faces {
  position: absolute;
  border-right: 2px solid rgba(255, 184, 0, 1);
  border-bottom: 2px solid rgba(255, 184, 0, 1);
  height: 100%;
  width: 100%;
  border-radius: 1px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden; /* Safari */
}

/*  FRONT STYLES */
/* alternative background "http://www.desktopimages.org/pictures/2014/0303/1/small-black-hexagon-pattern-wallpaper-60021.jpg" */
.front {
  background-color: #0d0e24;
  background-image: url("./cardbg.png");
  background-size: contain;
  /* height:400px", */
  background-position: 100% 150px;
  background-repeat: no-repeat;
  /* box-shadow: inset 0px 200px 350px #0D0E24; */
}
.logo {
  width: 10%;
  height: 16.5%;
  border-radius: 50%;
  border-top: 3px solid #3a3a3c;
  border-left: 3px solid #3a3a3c;
  border-right: 3px solid rgba(255, 184, 0, 1);
  border-bottom: 3px solid #3a3a3c;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 40%;
  left: 9%;
  transform: scale(1.5);
  padding: 5px;
}

.jedi-icon {
  color: rgba(255, 184, 0, 1);
  font-size: 150%;
}
.front-title {
  position: absolute;
  top: 32%;
  left: 25%;
  text-align: center;
  color: rgba(255, 184, 0, 1);
  letter-spacing: 2px;
  font-family: "Open Sans", sans-serif;
  border-left: 2px solid #3a3a3c;
  padding-left: 1.75%;
  line-height: 340%;
  align-self: flex-end;
  text-transform: uppercase;
  font-weight: 800;
}

.front-sub-title {
  color: rgba(255, 184, 0, 1);
  align-self: flex-start;
  position: absolute;
  bottom: 40%;
  left: 30.5%;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 600;
}

/*  BACK STYLES */
.back {
  background-color: #0d0e24;
  background-image: url("./cardbg.png");
  background-size: contain;
  height: 500px;
  background-position: 300px 400px;
  background-repeat: no-repeat;
  box-shadow: inset 0px 200px 350px #0d0e24;
  transform: rotateY(180deg);
}
.logo-back {
  margin-right: 10px;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border-top: 3px solid #3a3a3c;
  border-left: 3px solid #3a3a3c;
  border-right: 3px solid rgba(255, 184, 0, 1);
  border-bottom: 3px solid #3a3a3c;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 20px;
  transform: scale(0.75);
}
.jedi-icon-back {
  color: rgba(255, 184, 0, 1);
  font-size: 24px;
}
.back-title {
  position: absolute;
  top: 8px;
  left: 130px;
  text-align: center;
  color: rgba(255, 184, 0, 1);
  font-size: 20px;
  letter-spacing: 2px;
  font-family: "Open Sans", sans-serif;
  border-left: 2px solid #3a3a3c;
  padding-left: 10px;
  align-self: flex-end;
  text-transform: uppercase;
  backface-visibility: hidden;
  transform: scale(0.75);
  font-weight: 700;
}

.back-name {
  position: absolute;
  left: 5%;
  right: 5%;
  top: 35%;
  color: #fff;
  font-weight: 300;
  font-size: 15px;
  text-align: justify;
}

.back-sub-name {
  position: absolute;
  left: 5%;
  top: 43%;
  color: #3a3a3c;
  /*   font-weight:300; */
  font-size: 1em;
}

.back-list {
  list-style: none;
  position: absolute;
  right: 5%;
  top: 55%;
}
.back-list-item {
  color: #fff;
  font-size: 70%;
}
.list-icon {
  color: rgba(255, 184, 0, 1);
  padding: 4px 5px 4px 0px;
}

.blue-bar {
  position: absolute;
  top: 30%;
  right: 0;
  width: 10px;
  height: 220px;
  background: rgba(255, 184, 0, 1);
}

.card.is-flipped {
  transform: rotateY(180deg);
}
